.contrato {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(150deg, #494ba7 50%, #25BFB2 50%); 
}

.contrato label{
    color: #fff;
    font-weight: 600;
}

.title-contrato{
    position: absolute;
    top: 20px;
    color: #fff;
}
.icon-troca {
    transition: transform 0.3s ease;
  }
  
  .icon-troca.rotated {
    transform: rotate(360deg); 
  }

  .textarea-resizable {
    resize: both; 
    overflow: auto; 
}

.form-check{
  border: 1px solid #fff;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
}