/* SectorSelection.css */
.sector-selection {
    background: linear-gradient(90deg, #464E80, #800020);
    padding: 20px;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #fff;
    text-align: center;
}

.sector-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sector {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    text-align: center;
    height: 300px;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px!important;
}
.sector-icon {
    font-size: 3rem;
    color: #000000;
    margin-bottom: 15px;
}

.sector h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.sector p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #666;
}

.sector a {
    display: flex;
    justify-content: center;
    margin-block: 10px;
    width: 100%;
}

.btn-sector {
    font-size: 1rem;
    transition: background-color 0.3s ease;
    width: 100%;
}

.btn-sector:hover {
    background-color: #0056b3;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
}

.vendas-detalhes {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    max-width: 500px;
    position: relative;
}

.vendas-detalhes .btn-closed {
    background-color: rgb(150, 1, 1);
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    color: #fff;
    border-radius: 50%;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.vendas-detalhes .btn-closed:hover {
    background-color: rgb(218, 3, 3);
}

@media screen and (max-width: 992px) {
    .sector-selection {
        height: auto;
    }
    .sector-container {
        flex-direction: column;
        align-items: center;
    }
}
