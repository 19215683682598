.sidebar {
    width: 60px;
    height: 100vh;
    background-color: #464E80;
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    display: flex;
    flex-direction: column;
    transition: width 0.3s;
    
}

.sidebar.open {
    width: 250px;
    overflow: auto;
}

.sidebar.open .nav-perfil img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
    display: inline;
}

.sidebar .nav-perfil {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.sidebar .nav-perfil img {
    display: none;
}

.sidebar .nav-perfil p {
    display: none;
    color: #fff;
    margin: 0;
    font-size: 23px;
    font-weight: 500;
}

.sidebar.open .nav-perfil p {
    display: block;
}

.options{
    margin-top: 140px;
    gap: 10px;
}
.sidebar.open .options{
    margin-top: 20px;
}
.sidebar .nav .nav-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar .nav .nav-link {
    padding: 10px;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
}

.sidebar .nav .nav-link span {
    margin-left: 10px;
    display: none;
}

.sidebar.open .nav .nav-link span {
    display: inline;
}

.sidebar .nav .nav-item {
    transition: background-color 0.3s ease, margin 0.3s ease; /* Transição suave */
}


.sidebar .nav .nav-item:hover{
    background-color: #34495e;
    letter-spacing: 2px;
    margin-top: -5px;
    scale: 1.05;
}

.sidebar.open .nav .nav-item:hover {
    background-color: #34495e;
    letter-spacing: 2px;
    scale: 1.05;
    margin-top: -5px;
    margin-left: 10px;
}

.nav-contato {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    display: none;
}

.sidebar.open .nav-contato {
    display: flex;
}

.sidebar-toggle {
    position: fixed;
    top: 15px;
    left: 10px;
    z-index: 1000;
}

.sidebar-toggle.open {
    top: 3px;
    left: 255px;
    background: #ff5141;
}
.icon-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.icon-tooltip::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -50%; 
    left: 100%;
    transform: translateX(-50%);
    background-color: black;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    font-size: 12px;
    z-index: 4;
}

.sidebar:not(.open) .icon-tooltip:hover::after {
    opacity: 1;
    visibility: visible;
}

/* Quando a sidebar está aberta */
.sidebar.open .icon-tooltip::after {
    display: none;
}


.box-script {
    width: 90vw;
    position: fixed;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow-y: auto;
    max-height: 80vh;
}

.box-script p {
    width: 100%;
    margin-bottom: 15px;
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
}

.box-script .close-script{
    text-align: end;
    color: red;
    font-weight: 700;
    font-size: 30px;
    cursor: pointer;
}