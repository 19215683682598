.ficha-monitoria {
    background: linear-gradient(150deg, #494ba7 50%, #25BFB2 50%); 
    height: 100vh;
    padding: 20px;
    border-radius: 10px;
    padding-inline: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.ficha-monitoria h2 ,
.ficha-monitoria h3{
    color: #fff;
}

.monitoria{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.box-quest {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.box-quest label{
    text-align: center;
}

.img-boleto{
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 210px;
}