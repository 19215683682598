.financeiro{
    height: 100vh;
    background: linear-gradient(150deg, #494ba7 50%, #25BFB2 50%); 
}

.encaminheCob{
    margin-top: 20px;
}
.card-cob{
    width: 450px!important;
}