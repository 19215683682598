.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.content-modal {
    background-color: #ffffff;
    padding: 30px 40px;
    border-radius: 15px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.scripts{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.close-modal{
    position: absolute;
    top: calc(35% - -33px);
    right: calc(50% - 240px);
    color: red;
    font-size: 30px;
    font-weight: 700;
    cursor: pointer;
}

