.dashboard {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

.bg-relatorio {
  display: flex;
  justify-content: space-between;
  width: 80%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.ranking {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: 20px;
  flex: 1;
}


.tabela-ranking {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 450px;
  background-color: #f9f9f9;
  border-radius: 0 0 15px 15px;
}

.podio {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.img-podio{
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  z-index: 1;
  top: -50px;
  border: 3px inset #727272;
}

.colocacao1,
.colocacao2,
.colocacao3 {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative; 
}

.colocacao1 {
  height: 300px;
  order: 2;
  background-color: #2f971a;
  border-radius: 15px 15px 0 0;
}

.colocacao2 {
  height: 230px;
  background-color: #e7bd00;
  border-radius: 15px 15px 0 0;
  order: 3;
}

.colocacao3 {
  height: 150px;
  order: 1;
  border-radius: 15px 15px 0 0;
  background-color: #c71010;
}


.tabela-ranking li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.tabela-ranking li:last-child {
  border-bottom: none;
}

.foto-ranking {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.operador-podio,
.vendas-podio {
  color: #fff;
  text-transform: capitalize;
  font-size: 19px;
  text-align: center;
}

.nome-operador {
  flex: 1;
  margin-left: 10px;
  color: #555;
  text-transform: capitalize;
}

.vendas-operador {
  color: #007bff;
  font-weight: bold;
}

.paginacao {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.paginacao button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 7px 10px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #0056b3;
}

.total-vendas {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.total-contrato{
  position: absolute;
  top: 10px;
}