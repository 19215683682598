.bg-contrato {
  background: linear-gradient(150deg, #494ba7 50%, #25BFB2 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bg-infos-contrato {
  background-color: #fff;
}
.page-break {
  page-break-before: always; /* Força a quebra de página antes deste elemento */
}

.dados-empresa h5 {
  background-color: #59afd6;
  padding-block: 10px;
  color: #fff;
}

.dados-empresa p {
  font-size: 15px;
}

.header img {
  width: 90%;
  height: 10vh;
}

.condicoes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.condicoes .text-condicoes {
  width: 900px;
  font-size: 12px;
  text-align: justify;
  padding-inline: 20px;
}

.image-container {
  display: flex;
  align-items: center;
}


.image-container .arrow {
  font-size: 70px;
}

.bonus {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bonus h5,
.condicoes h5 {
  width: 100%;
  background-color: #59afd6;
  color: #Fff;
}

.bonus p {
  width: 600px;
  font-size: 12px;
}

.upper p{
  text-transform: uppercase!important;
}

.upper-span span{
  text-transform: uppercase!important;
}


.boleto-container {
  font-family: Arial, sans-serif;
  max-width: 700px;
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #333;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.boleto-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.boleto-logo img {
  width:70px ;
}

.gravacao{
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.qrcode-container {
  display: flex;
  align-items: center;
}

.qrcode-link {
  display: flex;
  align-items: center;
  gap: 10px; /* Ajuste o espaço entre a imagem e o QR code */
}

.qrcode img {
  width: 100px;
}

.qrcode svg {
  width: 70px; /* Tamanho do QR Code */
  height: 70px; /* Tamanho do QR Code */
}

.obs{
  font-size: 14px;
}


@media print {
  a {
    text-decoration: none;
    color: black;
    pointer-events: none; /* Desativa interatividade */
  }
}